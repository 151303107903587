/* eslint-disable */

<template>
  <div id="Lgx240573918642509837">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Protocol List</h3>
            <vue-draggable-container id="wordBank" class="list wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer ports-block" v-for="protocol in protocols" :key="protocol" :id="protocol">
                {{ protocol }}
              </h5>
            </vue-draggable-container>
          </vx-card>
          <vx-card class="" title="Page Switcher">
            <div class="flex items-center gap-x-3">
              <vs-button
                color="danger"
                type="filled"
                icon-pack="feather"
                icon="icon-arrow-left-circle"
                style="width: 100%"
                :disabled="currentPage === 0"
                class="font-bold"
                button
                size="medium"
                @click="previous_page"
                >Previous
              </vs-button>
              <vs-button
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-arrow-right-circle"
                style="width: 100%; z-index: 1"
                icon-after
                :disabled="currentPage === 3"
                class="font-bold flex"
                button
                size="medium"
                @click="next_page"
                >Next
              </vs-button>
            </div>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <div class="grid grid-cols-1 gap-4" :style="{ display: currentPage === 0 ? '' : 'none!important' }">
            <div class="answer-container" v-for="(scenario, index) in paginatedScenarios(0)" :key="scenario.id">
              <h5 class="flex items-center" style="flex-basis: 50%">{{ scenario.scenario }}</h5>

              <div class="flex items-center justify-between w-full gap-x-4" style="flex-basis: 50%">
                <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" :id="'input' + scenario.id">
                </vue-draggable-container>

                <v-select
                  placeholder="Select a port"
                  class="w-full flex h-full rounded-lg"
                  :id="'select' + scenario.id"
                  :options="ports"
                  v-model="scenario.portAnswer"
                />
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 gap-4" :style="{ display: currentPage === 1 ? '' : 'none!important' }">
            <div class="answer-container" v-for="(scenario, index) in paginatedScenarios(1)" :key="scenario.id">
              <h5 class="flex items-center" style="flex-basis: 50%">{{ scenario.scenario }}</h5>

              <div class="flex items-center justify-between w-full gap-x-4" style="flex-basis: 50%">
                <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" :id="'input' + scenario.id">
                </vue-draggable-container>

                <v-select
                  placeholder="Select a port"
                  class="w-full flex h-full"
                  :id="'select' + scenario.id"
                  :options="ports"
                  v-model="scenario.portAnswer"
                />
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 gap-4" :style="{ display: currentPage === 2 ? '' : 'none!important' }">
            <div class="answer-container" v-for="(scenario, index) in paginatedScenarios(2)" :key="scenario.id">
              <h5 class="flex items-center" style="flex-basis: 50%">{{ scenario.scenario }}</h5>

              <div class="flex items-center justify-between w-full gap-x-4" style="flex-basis: 50%">
                <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" :id="'input' + scenario.id">
                </vue-draggable-container>

                <v-select
                  placeholder="Select a port"
                  class="w-full flex h-full"
                  :id="'select' + scenario.id"
                  :options="ports"
                  v-model="scenario.portAnswer"
                />
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 gap-4" :style="{ display: currentPage === 3 ? '' : 'none!important' }">
            <div class="answer-container" v-for="(scenario, index) in paginatedScenarios(3)" :key="scenario.id">
              <h5 class="flex items-center" style="flex-basis: 50%">{{ scenario.scenario }}</h5>

              <div class="flex items-center justify-between w-full gap-x-4" style="flex-basis: 50%">
                <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg w-full" :id="'input' + scenario.id">
                </vue-draggable-container>

                <v-select
                  placeholder="Select a port"
                  class="w-full flex h-full"
                  :id="'select' + scenario.id"
                  :options="ports"
                  v-model="scenario.portAnswer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-sortable>
    <vs-popup :active.sync="remindPopup" class="pb-3" title="Have You Completed The Other Pages?">
      <div class="flex justify-center mt-3 mb-5">
        <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/danger.png" width="150" alt="Check" />
      </div>
      <div class="pl-2 pr-2">
        <h1 class="text-center font-bold my-6" style="font-size: 42px">Have You Completed The Other Pages?</h1>
        <h3 class="text-center font-light mb-3">Use the Page Switcher at the bottom left hand of the screen to switch pages.</h3>
      </div>
      <div class="flex justify-center mt-3">
        <vs-button class="mt-5 mb-5 mr-1" color="danger" icon-pack="feather" icon="icon-x-circle" @click="markSimulationDenied" size="large"
          >No, I Haven't</vs-button
        >
        <vs-button
          class="mt-5 mb-5 ml-1"
          color="success"
          icon-pack="feather"
          icon="icon-check-circle"
          @click="markSimulationConfirmed"
          size="large"
          >Yes, I Have</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';
import vSelect from 'vue-select';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      currentPage: 0,
      itemsPerPage: 8,
      remindPopup: false,

      protocols: [
        'FTP',
        'TFTP',
        'SFTP',
        'SCP',
        'SMTP',
        'POP3',
        'IMAP4',
        'Kerberos',
        'TACAS+',
        'Radius',
        'LDAP',
        'sLDAP',
        'HTTP',
        'HTTPS',
        'DHCP',
        'DNS',
        'NetBIOS',
        'Telnet',
        'sTelnet',
        'SSH',
        'RDP',
        'IPSec',
        'L2TP',
        'PPTP',
        'SNMP',
        'SQL Server',
      ],
      ports: [21, 69, 22, 25, 110, 993, 88, 49, 1812, 636, 80, 443, 67, 53, 137, 23, 389, 3389, 500, 1701, 1723, 161, 1433],

      scenarios: [
        { id: 1, scenario: 'This is a placeholder scenario1', protocol: 'FTP', port: 21, portAnswer: null },
        { id: 2, scenario: 'This is a placeholder scenario2', protocol: 'TFTP', port: 69, portAnswer: null },
        { id: 3, scenario: 'This is a placeholder scenario3', protocol: 'SFTP', port: 22, portAnswer: null },
        { id: 4, scenario: 'This is a placeholder scenario4', protocol: 'SCP', port: 22, portAnswer: null },
        { id: 5, scenario: 'This is a placeholder scenario5', protocol: 'SMTP', port: 25, portAnswer: null },
        { id: 6, scenario: 'This is a placeholder scenario6', protocol: 'POP3', port: 110, portAnswer: null },
        { id: 7, scenario: 'This is a placeholder scenario7', protocol: 'IMAP4', port: 993, portAnswer: null },
        { id: 8, scenario: 'This is a placeholder scenario8', protocol: 'Kerberos', port: 88, portAnswer: null },
        { id: 9, scenario: 'This is a placeholder scenario9', protocol: 'TACAS+', port: 49, portAnswer: null },
        { id: 10, scenario: 'This is a placeholder scenario10', protocol: 'Radius', port: 1812, portAnswer: null },
        { id: 11, scenario: 'This is a placeholder scenario11', protocol: 'LDAP', port: 389, portAnswer: null },
        { id: 12, scenario: 'This is a placeholder scenario12', protocol: 'sLDAP', port: 636, portAnswer: null },
        { id: 13, scenario: 'This is a placeholder scenario13', protocol: 'HTTP', port: 80, portAnswer: null },
        { id: 14, scenario: 'This is a placeholder scenario14', protocol: 'HTTPS', port: 443, portAnswer: null },
        { id: 15, scenario: 'This is a placeholder scenario15', protocol: 'DHCP', port: 67, portAnswer: null },
        { id: 16, scenario: 'This is a placeholder scenario16', protocol: 'DNS', port: 53, portAnswer: null },
        { id: 17, scenario: 'This is a placeholder scenario17', protocol: 'NetBIOS', port: 137, portAnswer: null },
        { id: 18, scenario: 'This is a placeholder scenario18', protocol: 'Telnet', port: 23, portAnswer: null },
        { id: 19, scenario: 'This is a placeholder scenario19', protocol: 'sTelnet', port: 22, portAnswer: null },
        { id: 20, scenario: 'This is a placeholder scenario20', protocol: 'sLDAP', port: 389, portAnswer: null },
        { id: 21, scenario: 'This is a placeholder scenario21', protocol: 'SSH', port: 22, portAnswer: null },
        { id: 22, scenario: 'This is a placeholder scenario22', protocol: 'RDP', port: 3389, portAnswer: null },
        { id: 23, scenario: 'This is a placeholder scenario23', protocol: 'IPSec', port: 500, portAnswer: null },
        { id: 24, scenario: 'This is a placeholder scenario24', protocol: 'L2TP', port: 1701, portAnswer: null },
        { id: 25, scenario: 'This is a placeholder scenario25', protocol: 'PPTP', port: 1723, portAnswer: null },
        { id: 26, scenario: 'This is a placeholder scenario26', protocol: 'SNMP', port: 161, portAnswer: null },
        { id: 27, scenario: 'This is a placeholder scenario27', protocol: 'SQL Server', port: 1433, portAnswer: null },
      ],

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard-ports-practice currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    previous_page() {
      this.currentPage--;
    },
    next_page() {
      this.currentPage++;
    },
    paginatedScenarios(page) {
      const startIndex = page * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.shuffledScenarios.slice(startIndex, endIndex);
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          x.cancel();
        }
      }
    },
    markSimulation() {
      if (this.currentPage === 0) {
        this.remindPopup = true;
        return this.$emit('simulation_getting_there', 0, false);
      }

      return this.markSimulationConfirmed();
    },
    markSimulationConfirmed() {
      this.remindPopup = false;
      let totalScore = 0;
      for (const scenario of this.scenarios) {
        const inputElement = document.getElementById(`input${scenario.id}`);
        const selectElement = document.getElementById(`select${scenario.id}`);
        const dragBlockElement = document.getElementById(scenario.protocol);

        if (inputElement) {
          if (inputElement.textContent === scenario.protocol) {
            inputElement.style.borderColor = '#181d2a';
            inputElement.style.backgroundColor = '#040815';

            dragBlockElement.classList.remove('dragBlock');
            dragBlockElement.classList.add('correctDragBlock');

            if (scenario.portAnswer === scenario.port) {
              totalScore++;

              if (selectElement) {
                selectElement.classList.add('selectionCorrect');
              }
            } else if (selectElement) {
              selectElement.classList.add('selectionIncorrect');
            }
          } else {
            if (inputElement.textContent !== '') {
              inputElement.style.borderColor = '#fd4445';
              inputElement.style.backgroundColor = '#fd4445';
            }

            if (scenario.portAnswer !== null) {
              if (scenario.portAnswer === scenario.port) {
                if (selectElement) {
                  selectElement.classList.add('selectionCorrect');
                }
              } else if (selectElement) {
                selectElement.classList.add('selectionIncorrect');
              }
            }
          }
        }
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    markSimulationDenied() {
      this.$vs.notify({
        title: 'Complete The Remaining Pages',
        text: 'Once you have completed the remaining pages, press submit to score your simulation.',
        color: 'warning',
        position: 'bottom-right',
        icon: 'feather',
        iconPack: 'icon-info',
      });
      this.remindPopup = false;
    },
  },
  computed: {
    shuffledScenarios() {
      return shuffle(this.scenarios);
    },
  },
  components: {
    draggable,
    Prism,
    shuffle,
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.ports = shuffle(this.ports);
  },
};
</script>

<style lang="scss" scoped>
.answerStandard {
  min-height: 44.5px !important;
}

.currently-dragging-standard-ports-practice {
  background-color: #02153d !important;
  border: 1px solid #0053ff !important;
  padding: 12px;
  color: white;
  z-index: 99;
  min-width: auto;
}

#wordBank {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
}

.ports-block {
  display: flex;
  max-height: 100px;
  padding: 10px;
  text-align: left;
  color: #fff;
  background-color: #02153d;
  border: 1px solid #0053ff;
  border-radius: 5px;
  font-size: 17px;
}

.answer-container {
  background-color: #02050d;
  border: 1px solid #181d2a;
  border-radius: 0.6rem;
  padding: 1rem;
  display: flex;
}

.answer-box {
  background-color: #040815;
  border: 1px solid #181d2a;
  border-radius: 0.6rem;
}
</style>

<style lang="scss">
#Lgx240573918642509837 {
  .vs__dropdown-toggle {
    width: 100% !important;
    border-radius: 0.6rem;
  }

  .selectionCorrect {
    border: 1px solid #31b952 !important;
    border-radius: 0.6rem;
  }

  .selectionIncorrect {
    border: 1px solid #c93738 !important;
    border-radius: 0.3rem;
  }

  .correctDragBlock {
    border: 1px solid #31b952 !important;
    background-color: #254e2f !important;
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 0.6rem;
    cursor: not-allowed !important;
  }
}
</style>
